import axios from 'axios';

export default class Vendedor {
  public static async get(id: number): Promise<GetVendedorResponse> {
    const response = await Vendedor.instance
      .get<GetVendedorResponse>('/vendedor.php', { params: { id } });
    return response.data;
  }

  private static instance = axios.create({
    baseURL: 'https://api.simulador.proteautobrasil.com.br/v1',
  });
}
