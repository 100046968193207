import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
});

import { VTextField } from 'vuetify/lib';

Vue.component('TextFieldOutlined', {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  }
});

import { VSelect } from 'vuetify/lib';

Vue.component('SelectOutlined', {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  }
});
