import axios from 'axios';

export default class Tabela {
  public static async get(valor: number): Promise<GetTabelaResponse> {
    const response = await Tabela.instance.get<GetTabelaResponse>(`/fipe/tabela.php?valor=${valor}&time=${new Date().getTime()}`);
    return response.data;
  }

  private static instance = axios.create({
    baseURL: 'https://sis.proteautobrasil.com.br/api/v1',
  });
}
