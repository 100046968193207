
import numbro from 'numbro';

const numbroBR =  {

  languageTag: 'pt-BR',

  delimiters: {
    thousands: '.',
    decimal: ',',
  },

  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't',
  },

  currency: {
    symbol: 'R$',
    position: 'prefix',
    code: 'BRL',
  },

  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },

  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    } as numbro.Format,
    fullWithTwoDecimals: {
      output: 'currency',
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    } as numbro.Format,
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    } as numbro.Format,
    fullWithNoDecimals: {
      output: 'currency',
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    } as numbro.Format,
  },

  ordinal() {
    return 'º';
  }
} as numbro.NumbroLanguage;

export default numbroBR;
