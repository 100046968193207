import { MutationsImpl } from 'direct-vuex';
import { vitalicio } from '.';

interface StoreMutations {
  SET_COTACAO(state: StoreState, payload: Cotacao): void;
  SET_NOME(state: StoreState, payload: string): void;
  SET_EMAIL(state: StoreState, payload: string): void;
  SET_DDD(state: StoreState, payload: string): void;
  SET_TELEFONE(state: StoreState, payload: string): void;
  SET_PLACA(state: StoreState, payload: string): void;
  SET_MARCA(state: StoreState, payload: {id: number, name: string}): void;
  SET_MODELO(state: StoreState, payload: {id: number, name: string}): void;
  SET_ANO(state: StoreState, payload: {id: string, name: string}): void;
  SET_COMBUSTIVEL(state: StoreState, payload: string): void;
  SET_CODIGO_FIPE(state: StoreState, payload: string): void;
  SET_VALOR_FIPE(state: StoreState, payload: number): void;
  SET_VALOR_PLANO(state: StoreState, payload: number): void;
  SET_VALOR_MENSAL(state: StoreState, payload: number): void;
  SET_VALOR_ADESAO(state: StoreState, payload: number): void;
  SET_COBERTURA(state: StoreState, payload: {label: string, value: string}): void;
  SET_COTACAO_ID(state: StoreState, payload: number): void;
  SET_PROTOCOLO(state: StoreState, payload: number): void;
  SET_COD_INDICADOR(state: StoreState, payload: number): void;
  SET_ID_INDICADOR(state: StoreState, payload: number): void;
  SET_INDICADOR(state: StoreState, payload: string): void;
  SET_VENDEDOR(state: StoreState, payload: Vendedor|null): void;

  SET_VEICULO_TIPO(state: StoreState, payload: string): void;
  SET_LISTA_PLACA_MODELOS(state: StoreState, payload: InfoFipe[]): void;
  SET_PLACA_NAO_ENCONTRADA(state: StoreState, payload: boolean): void;
  SET_LOADING_PLACA(state: StoreState, payload: boolean): void;
  SET_PESQUISA_PLACA(state: StoreState, payload: boolean): void;

  SET_LISTA_MARCAS(state: StoreState, payload: FipeMarca[]): void;
  SET_LISTA_MODELOS(state: StoreState, payload: FipeModelo[]): void;
  SET_LISTA_ANOS(state: StoreState, payload: FipeVeiculoAno[]): void;
  SET_LOADING_MARCAS(state: StoreState, payload: boolean): void;

  SET_LOADING_MODELOS(state: StoreState, payload: boolean): void;
  SET_LOADING_ANOS(state: StoreState, payload: boolean): void;
  SET_LOADING_FIPE(state: StoreState, payload: boolean): void;
  SET_LOADED_FIPE(state: StoreState, payload: boolean): void;
  SET_ORIGEM_FIPE(state: StoreState, payload: boolean): void;

  SET_ERRORS(state: StoreState, payload: DadosErrors): void;
  ATIVAR_VALIDACAO(state: StoreState): void;

  SET_SIDEBAR_ENABLED(state: StoreState, payload: boolean): void;
  SET_SIDEBAR_SHOWING(state: StoreState, payload: boolean): void;
}

const mutations = {
  SET_COTACAO: (state, payload) => {
    const valorPlano = payload.valor_plano - vitalicio;
    payload.valor_plano = valorPlano;
    state.cotacao = payload;
  },
  SET_NOME: (state, payload) => (state.cotacao.nome = payload),
  SET_EMAIL: (state, payload) => (state.cotacao.email = payload),
  SET_DDD: (state, payload) => (state.cotacao.ddd = payload),
  SET_TELEFONE: (state, payload) => (state.cotacao.telefone = payload),
  SET_PLACA: (state, payload) => (state.cotacao.placa = payload),
  SET_MARCA: (state, payload) => {
    state.simulacao.marca_id = payload.id;
    state.cotacao.marca = payload.name;
  },
  SET_MODELO: (state, payload) => {
    state.simulacao.modelo_id = payload.id;
    state.cotacao.modelo = payload.name;
  },
  SET_ANO: (state, payload) => {
    state.simulacao.ano_id = payload.id;
    state.cotacao.ano = payload.name;
  },
  SET_COMBUSTIVEL: (state, payload) => (state.cotacao.combustivel = payload),
  SET_CODIGO_FIPE: (state, payload) => (state.cotacao.fipe_codigo = payload),
  SET_VALOR_FIPE: (state, payload) => (state.cotacao.valor_fipe = payload),
  SET_VALOR_PLANO: (state, payload) => (state.cotacao.valor_plano = payload),
  SET_VALOR_MENSAL: (state, payload) => (state.cotacao.valor_mensalidade = payload),
  SET_VALOR_ADESAO: (state, payload) => (state.cotacao.valor_adesao = payload),
  SET_COBERTURA: (state, payload) => {
    state.cotacao.cobertura = payload.label;
    state.cotacao.valor_cobertura = parseFloat(payload.value);
  },
  SET_COTACAO_ID: (state, payload) => (state.cotacao.id = payload),
  SET_PROTOCOLO: (state, payload) => (state.cotacao.protocolo = payload),
  SET_COD_INDICADOR: (state, payload) => (state.cotacao.cod_indicador = payload),
  SET_ID_INDICADOR: (state, payload) => (state.simulacao.id_indicador = payload),
  SET_INDICADOR: (state, payload) => (state.simulacao.indicador = payload),
  SET_VENDEDOR: (state, payload) => {
    state.vendedor = payload;
    if (payload) {
      state.cotacao.vendedor = payload.id;
    }
  },

  SET_VEICULO_TIPO: (state, payload) => (state.cotacao.tipo = parseInt(payload, 10)),
  SET_LISTA_PLACA_MODELOS: (state, payload) => (state.placa_modelos = payload),
  SET_PLACA_NAO_ENCONTRADA: (state, payload) => (state.placa_nao_encontrada = payload),
  SET_LOADING_PLACA: (state, payload) => (state.loading_placa = payload),
  SET_PESQUISA_PLACA: (state, payload) => (state.pesquisa_placa = payload),

  SET_LISTA_MARCAS: (state, payload) => (state.marcas = payload),
  SET_LISTA_MODELOS: (state, payload) => (state.modelos = payload),
  SET_LISTA_ANOS: (state, payload) => (state.anos = payload),
  SET_LOADING_MARCAS: (state, payload) => (state.loading_marcas = payload),

  SET_LOADING_MODELOS: (state, payload) => (state.loading_modelos = payload),
  SET_LOADING_ANOS: (state, payload) => (state.loading_anos = payload),
  SET_LOADING_FIPE: (state, payload) => (state.loading_fipe = payload),
  SET_LOADED_FIPE: (state, payload) => (state.loaded_fipe = payload),
  SET_ORIGEM_FIPE: (state, payload) => (state.origem_fipe = payload),

  SET_ERRORS: (state, payload) => (state.errors = payload),
  ATIVAR_VALIDACAO: (state) => (state.validacao = true),

  SET_SIDEBAR_ENABLED: (state, payload) => (state.sidebar_enabled = payload),
  SET_SIDEBAR_SHOWING: (state, payload) => (state.sidebar_showing = payload),
} as StoreMutations & MutationsImpl<StoreState>;

export default mutations;
