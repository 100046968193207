const state: StoreState = {
  cotacao: {
    id: 0,
    protocolo: 0,
    nome: '',
    ddd: '',
    telefone: '',
    email: '',
    tipo: 3,
    placa: '',
    marca: '',
    modelo: '',
    ano: '',
    combustivel: '',
    fipe_codigo: '',
    plano: '',
    valor_plano: 0,
    valor_fipe: 0,
    valor_mensalidade: 0,
    valor_adesao: 0,
    cobertura: 'Sem Cobertura',
    valor_cobertura: 0,
    cod_indicador: 0,
  } as Cotacao,

  simulacao: {},

  vendedor: null,

  validacao: false,
  errors: {},
  marcas: [],
  modelos: [],
  anos: [],

  pesquisa_placa: false,
  placa_modelos: [],
  loading_placa: false,
  placa_nao_encontrada: false,

  loading_marcas: false,
  loading_modelos: false,
  loading_anos: false,
  loading_fipe: false,
  loaded_fipe: false,
  timestamp: Math.round(new Date().getTime() / 1000),
  origem_fipe: false,

  sidebar_enabled: false,
  sidebar_showing: false,

  configuracoes: {
    abaixo_110: 345,
  },
};

export default state;
