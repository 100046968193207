import { ActionContext } from 'vuex';
import { ActionsImpl } from 'direct-vuex';
import { rootActionContext } from "@/store";
import Simulador from '@/plugins/api/Simulador';
import Tabela from '@/plugins/api/Tabela';
import Fipe from '@/plugins/api/Fipe';
import Placa from '@/plugins/api/Placa';
import Vendedor from "@/plugins/api/Vendedor";

interface StoreActions extends ActionsImpl {
  LOAD_COTACAO(store: ActionContext<StoreState, any>, payload: number): Promise<any>;
  SET_VENDEDOR(store: ActionContext<StoreState, any>, payload: number): Promise<any>;
  LOAD_MARCAS(store: ActionContext<StoreState, any>): Promise<any>;
  SET_PLACA(store: ActionContext<StoreState, any>, payload: string): Promise<any>;
  SET_FIPE(store: ActionContext<StoreState, any>, payload: string): Promise<any>;
  SET_MARCA(store: ActionContext<StoreState, any>, payload: {id: number, name: string}): Promise<any>;
  SET_MODELO(store: ActionContext<StoreState, any>, payload: {id: number, name: string}): Promise<any>;
  SET_ANO(store: ActionContext<StoreState, any>, payload: {id: string, name: string}): Promise<any>;
  FROM_COD_ANO(store: ActionContext<StoreState, any>, payload: DataLandingPage): Promise<any>;
  GET_VALOR_TABELA(store: ActionContext<StoreState, any>): Promise<any>;
  VALIDAR_DADOS(store: ActionContext<StoreState, any>, payload: boolean): Promise<any>;
  VALIDAR_DADOS_FORM(store: ActionContext<StoreState, any>): Promise<any>;
}

const actions = {
  async LOAD_COTACAO (context, payload) {
    const { commit } = rootActionContext(context);

    const response = await Simulador.get(payload);
    commit.SET_COTACAO(response.data);
  },
  async SET_VENDEDOR (context, payload) {
    const { commit } = rootActionContext(context);

    const response = await Vendedor.get(payload);
    commit.SET_VENDEDOR(response.data);
  },
  async LOAD_MARCAS(context) {
    const { commit } = rootActionContext(context);

    commit.SET_LOADING_MARCAS(true);
    const data = await Fipe.getMarcas();
    commit.SET_LISTA_MARCAS(data);
    commit.SET_LOADING_MARCAS(false);
  },
  async SET_PLACA(context, payload) {
    const { commit, dispatch } = rootActionContext(context);

    commit.SET_PLACA(payload);
    commit.SET_PLACA_NAO_ENCONTRADA(false);
    if ( payload.length === 7) {
      commit.SET_LOADING_PLACA(true);

      try {
        const data = await Placa.get(payload);

        const anos = data.dados_do_veiculo.ano.split('/') as string[];
        const modeloAno = anos[anos.length - 1];
        commit.SET_ANO({ id: `${modeloAno}-3`, name: modeloAno });
        commit.SET_LISTA_PLACA_MODELOS(data.fipes);

        if (data.fipes.length === 0) {
          throw new Error();
        } else if (data.fipes.length === 1) {
          const fipe = data.fipes[0].codigo;
          dispatch.SET_FIPE(fipe);
        }
      } catch (error) {
        commit.SET_PLACA_NAO_ENCONTRADA(true);
        commit.SET_LISTA_PLACA_MODELOS([]);
      } finally {
        commit.SET_LOADING_PLACA(false);
      }
    }
  },
  async SET_FIPE(context, payload) {
    const { state, commit, dispatch } = rootActionContext(context);

    commit.SET_CODIGO_FIPE(payload);
    commit.SET_PLACA_NAO_ENCONTRADA(false);
    commit.SET_LOADING_PLACA(true);
    try {
      const data = await Fipe.getFipe(payload);
      const fipe = data.find( (opt: FipeVeiculo) => opt.ano === state.cotacao.ano );

      if (fipe) {
        const marca = parseInt(fipe.id_marca, 10);
        const ano = parseInt(fipe.id_modelo_ano, 10);
        const preco = fipe.preco.replace('R$', '').replace('.', '').replace(',', '.').trim();

        commit.SET_VEICULO_TIPO(fipe.tipo);
        commit.SET_LOADED_FIPE(true);
        commit.SET_MARCA({ id: marca, name: fipe.marca });
        commit.SET_MODELO({ id: ano, name: fipe.name });
        commit.SET_COMBUSTIVEL(fipe.combustivel);
        commit.SET_VALOR_FIPE(parseFloat(preco));
        dispatch.GET_VALOR_TABELA();
      } else {
        throw new Error("");
      }
    } catch (error) {
      commit.SET_LISTA_PLACA_MODELOS([]);
    } finally {
      commit.SET_LOADING_PLACA(false);
    }
  },
  async SET_MARCA(context, payload) {
    const { state, commit } = rootActionContext(context);

    commit.SET_LOADING_MODELOS(true);
    commit.SET_MARCA(payload);
    commit.SET_LISTA_MODELOS([]);
    commit.SET_LISTA_ANOS([]);

    const marca = state.simulacao.marca_id as number;
    const data = await Fipe.getModelos(marca);

    commit.SET_LISTA_MODELOS(data);
    commit.SET_LOADING_MODELOS(false);
  },
  async SET_MODELO(context, payload) {
    const { state, commit } = rootActionContext(context);

    commit.SET_LOADING_ANOS(true);
    commit.SET_MODELO(payload);
    commit.SET_LISTA_ANOS([]);

    const marca = state.simulacao.marca_id as number;
    const modelo = state.simulacao.modelo_id as number;
    const data = await Fipe.getAnos(marca, modelo);

    data.forEach((value, index) => {
      const val = value.name.toString();
      data[index].label = val === '32000' ? '0 KM' : val;
    });

    commit.SET_LISTA_ANOS(data);
    commit.SET_LOADING_ANOS(false);
  },
  async SET_ANO(context, payload) {
    const { state, commit, dispatch } = rootActionContext(context);

    commit.SET_LOADING_FIPE(true);
    commit.SET_ANO(payload);

    const marca = state.simulacao.marca_id as number;
    const modelo = state.simulacao.modelo_id as number;
    const ano = state.simulacao.ano_id as string;

    const data = await Fipe.getVeiculo(marca, modelo, ano);
    commit.SET_CODIGO_FIPE(data.fipe_codigo);

    const preco = parseFloat(
      data.preco.replace('R$', '').split('.').join('').replace(',', '.')
    );
    commit.SET_VALOR_FIPE(preco);
    commit.SET_COMBUSTIVEL(data.combustivel);

    await dispatch.GET_VALOR_TABELA();
    commit.SET_LOADING_FIPE(false);
    commit.SET_LOADED_FIPE(true);
  },
  async FROM_COD_ANO(context, payload) {
    const { state, commit, dispatch } = rootActionContext(context);

    // Evita que consulte os dados novamente caso a pessoa volte a tela inicial
    if (!state.origem_fipe) {
      commit.SET_ORIGEM_FIPE(true);
      commit.SET_CODIGO_FIPE(payload.fipe!);
      commit.SET_ANO({ id: `${payload.ano}-3`, name: payload.ano! });
      commit.SET_PLACA(payload.placa!);

      const data = await Fipe.getFipe(payload.fipe!);

      const selected = data.find((item) => item.id_modelo_ano === state.simulacao.ano_id);

      if (selected) {
        const idMarca = parseInt(selected.id_marca, 10);
        const idModelo = parseInt(selected.id_modelo, 10);
        const idAno = selected.id_modelo_ano;

        commit.SET_MARCA({ id: idMarca, name: selected.marca });
        commit.SET_MODELO({ id: idModelo, name: selected.name });
        commit.SET_ANO({ id: idAno, name: selected.name });

        const preco = parseFloat(
          selected.preco.replace('R$', '').split('.').join('').replace(',', '.')
        );

        commit.SET_VALOR_FIPE(preco);
        commit.SET_COMBUSTIVEL(selected.combustivel);

        dispatch.GET_VALOR_TABELA().then(() => {
          commit.SET_LOADING_FIPE(false);
          commit.SET_LOADED_FIPE(true);
        });
      }
    }
  },
  async GET_VALOR_TABELA(context) {
    const { state, commit } = rootActionContext(context);

    let valor = state.cotacao.valor_fipe;

    const data = await Tabela.get(valor);

    let mensalParcial = data.tabela['0.32'];
    if (valor <= 110000) {
      valor = 110000;
      mensalParcial = state.configuracoes.abaixo_110;
    }
    commit.SET_VALOR_PLANO(mensalParcial);
    commit.SET_VALOR_ADESAO(data.adesao);
    return data;
  },
  async VALIDAR_DADOS(context, payload) {
    const { state, commit } = rootActionContext(context);

    if (payload) {
      commit.ATIVAR_VALIDACAO();
    }
    if (state.validacao) {
      const errors: DadosErrors = {};
      if (state.cotacao.nome.length < 1) {
        errors.nome = true;
      }
      if (state.cotacao.ddd.length < 2) {
        errors.ddd = true;
      }
      if (state.cotacao.telefone.length < 9) {
        errors.telefone = true;
      }
      if (!state.loaded_fipe) {
        errors.fipe = true;
      }
      if (!state.simulacao.marca_id) {
        errors.marca = true;
      }
      if (!state.simulacao.modelo_id) {
        errors.modelo = true;
      }
      if (state.simulacao.ano_id === '') {
        errors.ano = true;
      }

      commit.SET_ERRORS(errors);

      if (Object.keys(errors).length > 0) {
        throw new Error();
      }
    }
  },
  async VALIDAR_DADOS_FORM(context) {
    const { state, commit } = rootActionContext(context);

    const errors: DadosErrors = {};
    if (state.cotacao.tipo !== 3) {
      errors.tipo = true;
    }
    if (state.pesquisa_placa) {
      if (state.cotacao.placa.length !== 7) {
        errors.placa = true;
      }
      if (state.cotacao.fipe_codigo === '') {
        errors.modelo = true;
      }
    } else {
      if (!state.simulacao.marca_id) {
        errors.marca = true;
      }
      if (!state.simulacao.modelo_id) {
        errors.modelo = true;
      }
      if (state.simulacao.ano_id === '') {
        errors.ano = true;
      }
    }

    commit.SET_ERRORS(errors);

    if (Object.keys(errors).length > 0) {
      throw new Error();
    }
  },
} as StoreActions;

export default actions;
