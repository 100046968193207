import axios from 'axios';

export default class Simulador {
  public static async get(id: number): Promise<CotacaoResponse> {
    const response = await Simulador.instance
      .get<CotacaoResponse>('/cotacao.php', { params: { id } });
    return response.data;
  }

  public static async create(data: {}): Promise<CriarCotacaoResponse> {
    const response = await Simulador.instance
      .post<CriarCotacaoResponse>('/cotacao.php', data);
    return response.data;
  }

  public static async update(data: {}): Promise<GenericResponse> {
    const response = await Simulador.instance
      .patch<GenericResponse>('/cotacao.php', data);
    return response.data;
  }

  private static instance = axios.create({
    baseURL: 'https://api.simulador.proteautobrasil.com.br/v1',
  });
}
