import Vue from 'vue';

import InputFacade from 'vue-input-facade';
Vue.use(InputFacade, {
  // use these tokens instead of the default
  tokens: {
    '#': { pattern: /\d/ },
    'A': { pattern: /[a-z]/i, transform: (v: string) => v.toLocaleUpperCase() },
    'D': { pattern: /[0-9a-z]/i , transform: (v: string) => v.toLocaleUpperCase() },
  }
});
