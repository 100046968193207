import axios from 'axios';

export default class Fipe {

  public static async getMarcas(): Promise<GetMarcasResponse> {
    const response = await Fipe.instance.get<GetMarcasResponse>(`/v1/3?${Fipe.key}`);
    return response.data;
  }

  public static async getModelos(marca: number): Promise<GetModelosResponse> {
    const response = await Fipe.instance.get<GetModelosResponse>(`/v1/3/${marca}?${Fipe.key}`);
    return response.data;
  }

  public static async getAnos(marca: number, modelo: number): Promise<GetAnosResponse> {
    const response = await Fipe.instance.get<GetAnosResponse>(`/v1/3/${marca}/${modelo}?${Fipe.key}`);
    return response.data;
  }

  public static async getVeiculo(marca: number, modelo: number, ano: string): Promise<GetVeiculoResponse> {
    const response = await Fipe.instance.get<GetVeiculoResponse>(`/v1/3/${marca}/${modelo}/${ano}?${Fipe.key}`);
    return response.data;
  }

  public static async getFipe(fipe: string): Promise<GetFipesResponse> {
    const response = await Fipe.instance.get<GetFipesResponse>(`/v1/fipe/${fipe}?${Fipe.key}`);
    return response.data;
  }

  private static key = '81c3491bd83d67d9ad889cca166ea3d9';
  private static instance = axios.create({
    baseURL: 'https://api.fipeapi.com.br',
  });
}
