import axios from 'axios';

export default class Placa {

  public static async get(placa: string): Promise<GetPlacaResponse> {
    const response = await Placa.instance.get<GetPlacaResponse>(`/${placa}`);
    return response.data;
  }

  private static instance = axios.create({
    baseURL: 'https://api.wipsites.com.br/dados/placa',
    headers: {
      Authorization: '1b83dc9e81206dacf7a6b31ff387fb53',
    },
  });
}
