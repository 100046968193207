import { rootGetterContext } from "@/store";
import * as EmailValidator from 'email-validator';
import numbro from 'numbro';
import numbroBR from '@/locales/numbro';
import { vitalicio } from '.';
import { GettersImpl } from 'direct-vuex';
numbro.registerLanguage(numbroBR);
numbro.setLanguage('pt-BR');

interface StoreGetters extends GettersImpl<StoreState, any> {
  show_sidebar(state: StoreState, getters: StoreGetters): boolean;
  veiculo_ano(state: StoreState, getters: StoreGetters): string;
  valor_plano(state: StoreState, getters: StoreGetters): number;
  valor_mensalidade(state: StoreState, getters: StoreGetters): number;
  valor_cortado(state: StoreState, getters: StoreGetters): number;
  valor_anual(state: StoreState, getters: StoreGetters): number;
  get_email(state: StoreState, getters: StoreGetters): string;
}

const gettersTree = {
  show_sidebar(...args) {
    const { state } = rootGetterContext(args);
    return state.sidebar_enabled ? state.sidebar_showing : false;
  },
  veiculo_ano(...args) {
    const { state } = rootGetterContext(args);
    return state.cotacao.ano === '32000' ? '0 KM' : state.cotacao.ano;
  },
  valor_plano(...args) {
    const { state } = rootGetterContext(args);
    
    return numbro(state.cotacao.valor_plano).add(vitalicio).value();
  },
  valor_mensalidade(...args) {
    const { state, getters } = rootGetterContext(args);

    return numbro(getters.valor_plano).add(state.cotacao.valor_cobertura).value();
  },
  valor_cortado(...args) {
    const { getters } = rootGetterContext(args);

    return numbro(getters.valor_mensalidade).multiply(1.1).value();
  },
  valor_anual(...args) {
    const { getters } = rootGetterContext(args);

    return numbro(getters.valor_mensalidade).multiply(12).value();
  },
  get_email(...args) {
    const { state } = rootGetterContext(args);

    if (EmailValidator.validate(state.cotacao.email)) {
      return state.cotacao.email;
    } else {
      return `${state.timestamp}@email.com`;
    }
  },
} as StoreGetters;

export default gettersTree;
